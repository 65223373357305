export const NetworkContextName = "Ethereum Mainnet";
export const ACTIVE_NETWORK = 1;
export const maxMint = 20;
export const GalacticEagleContract =
  "0x876618b1812cd67497a872c56f82ad4e91872454";

export const RPC_URL =
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

export const NetworkDetails = [
  {
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ethereum Mainnet",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
];

export const countDownDate = new Date("Nov 19, 2021 08:00:00 PST").getTime();
export const countDownDate1 = countDownDate.toLocaleString("en-US", {
  timeZone: "America/New_York",
});

import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {

    MuiAccordionDetails:{
      root: {
        display: "block",
        
    },
  

    },
  
    MuiMenuItem:
    {
      root: {
        color: "#9f9f9f",
        width: "auto",
        overflow: "hidden",
        fontSize: "14px",
        boxSizing: "border-box",
        fontFamily: "'Futura'",
        minHeight: "48px",
        fontWeight: "400",
        lineHeight: "28px",
        paddingTop: "6px",
        whiteSpace: "nowrap",
        paddingBottom: "6px",
    },
    },


    MuiIconButton: {
      root: {
        flex: "0 0 auto",
      color: " #FFFFFF",
      padding: "12px",
      overflow: "visible",
      fontSize: "1.5rem",
      textAlign: "center",
     transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: "50%",

      },

    },

    MuiToolbar: {
      regular: {
        minHeight: "0px !important",
        "@media (max-width: 1024px)": {
          minHeight: "70px !important",
        },
      },
    },
    MuiAppBar: {
      positionFixed: {
        backgroundColor: "#272a30",
      },

      positionAbsolute: {
        top: "10",
        left: "auto",
        right: "0",
        position: "absolute",

      },
    },
    MuiSelect: {
      outlined: {
        paddingTop: "7px",
      },
    },
    MuiSlider: {
      root: {
        color: " #ffffff",
        height: "10px",
      },
      track: {
        height: "10px",
        borderRadius: "50px!important",
        backgroundColor: "#5dcadb !important",
      },
      rail: {
        height: "10px",
        borderRadius: "50px!important",
        backgroundColor: "#484748 !important",
        opacity: 1,
      },
      thumb: {
        width: "20px",
        height: "20px",
      },
      valueLabel: {
        left: "auto",
      },
      mark: {
        display: "none",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "2px solid rgb(0 0 0)",
        backgroundColor: "#272a30",
        color: "#fff !important",
        padding: "5px 16px",
      },
    },
    PrivateValueLabel: {
      circle: {
        width: "32px",
        height: "32px",
       display:"flex",
        transform: "rotate(-45deg)",
        alignItems: "center",
        borderRadius: "50% 50% 50% 0",
        justifyContent: "center",
        backgroundColor: "currentColor",
    },
      label: {
        color: " #000",
      },
    },
    MuiDialogContent: {
      root: {
        flex: "1 1 auto",
       padding: "8px 24px",
      overflowY: "auto",
        '&:first-child': {
          paddingTop: 0,
        },
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiList: {
      padding: {
        padding: "10px",
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {

      root: {
        position: "relative",



      },
      notchedOutline: {
        borderColor: "rgb(8 8 8) !important",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#fff",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#ffff",
        },
      },
    },
    MuiPaper: {
      root: {
        color: "#52565c",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#1c2128",

      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
    },
    MuiInputBase: {
      root: {
        color: "#52565c",
        cursor: "text",
        display: "inline-flex",
        position: "relative",
        fontSize: "16px",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "400",
        lineHeight: "1.1876em",
        background: "#5dcadb",

      },
      input: {
        fontSize: 14,
        font: "caption",
        color: "#fff",
        width: "100%",
        height: "1.1876em",
        margin: "0",
        display: "block",
        padding: "6px 0 7px",
        minWidth: "0",
        background: "#3E3E3E",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      root: {
    padding: "6px 16px",
    fontSize: "16px",
    minWidth: "44px",
    boxSizing: "border-box",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontWeight: "500",
    lineHeight: "1.75",
    borderRadius: "4px",
    textTransform: "capitalize",
    color: "#fff",
        "&:hover": {
          backgroundColor: "#3e3e3e",
        },
      },
      containedPrimary:{
        height: "47px",
        border: "2px solid #5dcadb",
        color:" #fff",
        backgroundColor: "#5dcadb",
        fontSize: "15px !important",
        lineHeight: " 21px",
        letterSpacing: "1px",
        fontWeight: "400",
        minWidth: "180px",
        "@media (max-width:767px)": {
          fontSize: "11px !important",
          minWidth: "120px",
        },
        "&:hover": {
          backgroundColor: "#000",
        },
      },
      containedSizeLarge:{
        padding:"8px 47px",
      },
      containedSecondary: {
        backgroundColor: "#2b4cf1",
        borderRadius: "7px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "#fff",
        minWidth: "180px",
        fontSize: "15px !important",
        transition: "0.5s",
        height: "46px",
        lineHeight: " 21px",
        letterSpacing: "1px",
        border: "1.5px solid #2b4cf1",
        marginRight: "10px",
        borderRadius: "7px",
        textTransform: "uppercase",
        fontWeight: "400",
        fontFamily: "'Poppins', sans-serif",
        "@media (max-width:767px)": {
          fontSize: "14px !important",
          minWidth: "120px",
        },
        "&:hover": {
          backgroundColor: "#000",
        },
      },
      contained: {
       
        color: "#f30065",
        fontWeight: 600,
        padding: "11px 22px ",
        "&:hover": {
          backgroundColor: "#08d57f",
        },
      },
      outlinedPrimary: {
        borderRadius: "50px",
        color: "#300760",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #300760",
        "&:hover": {
          backgroundColor: "#f30065",
          border: "2px solid #f30065",
          color: "#000",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      colorPrimary: {
        color: "#898989",
      },
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

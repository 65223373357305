import React, { createContext, useEffect, useState } from "react";
import { injected, walletlink } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, GalacticEagleContract } from "src/constants";
import {
  swichNetworkHandler,
  getWeb3ContractObject,
  getBalanceOf,
  getWeb3Obj,
  getContract,
} from "src/utils";
import GalacticEagleABI from "src/ABI/GalacticEagleABI.json";
import axios from "axios";
import { toast } from "react-toastify";
import { SUPPORTED_WALLETS } from "src/connectors";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, chainId, library, account } = useWeb3React();
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [nftPrice, setNftPrice] = useState(0);
  const [userNFTList, setUserNFTList] = useState([]);
  const [MAX_MINT, setMAX_MINT] = useState(0);
  const [MAX_NFT_WALLET, setMAX_NFT_WALLET] = useState(0);
  // const [vipPresaleStartTimestamp, setvipPresaleStartTimestamp] = useState(0);
  // const [wlPresaleStartTimestamp, setwlPresaleStartTimestamp] = useState(0);
  // const [publicSaleStartTimestamp, setPublicSaleStartTimestamp] = useState(0);
  const [saleActive, setSaleActive] = useState("No Sale");

  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;

      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          toast.error(JSON.stringify(error.message));
          window.sessionStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  // const connectWalletHandler = async () => {
  //   activate(walletlink, undefined, true).catch((error) => {
  //     if (error) {
  //       toast.error(error.message);
  //       activate(walletlink);
  //     }
  //   });
  // };
  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      if (selectectWalletDetails[0]?.data) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, []);

  let data = {
    // publicSaleStartTimestamp,
    // vipPresaleStartTimestamp,
    // wlPresaleStartTimestamp,
    balanceOfValue,
    saleActive,
    MAX_NFT_SUPPLY,
    MAX_MINT,
    MAX_NFT_WALLET,
    totalSupply,
    adminWalletAddress,
    nftPrice,
    userNFTList,

    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectWalletHandler(data),
    getCurrentMintingDetails: () => getCurrentMintingDetails(),
  };

  const userNFTListHadler = async (balanceOf, cancelTokenSource) => {
    setUserNFTList([]);
    const contract = getContract(
      GalacticEagleContract,
      GalacticEagleABI,
      library,
      account
    );

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());

        const res = await axios.get(filter, {
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        });
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (balanceOfValue > 0) {
      userNFTListHadler(balanceOfValue, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [balanceOfValue, account, chainId]); //eslint-disable-line

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account, chainId]); //eslint-disable-line

  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(GalacticEagleABI, GalacticEagleContract, account)
    );
  }

  const getContractDetails = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(
        GalacticEagleABI,
        GalacticEagleContract
      );
      const adminAccount = await contractObj.methods.owner().call();
      setAdminWalletAddress(adminAccount);
      console.log("adminAccount", adminAccount);

      // const vipPresaleStartTimestamp = await contractObj.methods
      //   .vipPresaleStartTimestamp()
      //   .call();
      // setvipPresaleStartTimestamp(vipPresaleStartTimestamp);
      // const wlPresaleStartTimestamp = await contractObj.methods
      //   .wlPresaleStartTimestamp()
      //   .call();
      // setwlPresaleStartTimestamp(wlPresaleStartTimestamp);

      // const publicSaleStartTimestamp = await contractObj.methods
      //   .publicSaleStartTimestamp()
      //   .call();

      // setPublicSaleStartTimestamp(publicSaleStartTimestamp);

      const saleActive = await contractObj.methods.saleActive().call();
      setSaleActive(saleActive);
      console.log("saleActive", saleActive);

      const MAX_MINT = await contractObj.methods.MAX_MINT().call();
      setMAX_MINT(MAX_MINT);
      const MAX_NFT_WALLET = await contractObj.methods.MAX_NFT_WALLET().call();
      setMAX_NFT_WALLET(MAX_NFT_WALLET);

      if (saleActive == "VIP Presale") {
        const NFT_PRICE = await contractObj.methods.VIP_PRESALE_PRICE().call();
        const getNFTPrice = await web3.utils.fromWei(NFT_PRICE.toString());
        setNftPrice(getNFTPrice);
      }

      if (saleActive == "WL Presale") {
        const NFT_PRICE = await contractObj.methods.WL_PRESALE_PRICE().call();
        const getNFTPrice = await web3.utils.fromWei(NFT_PRICE.toString());
        setNftPrice(getNFTPrice);
      }

      if (saleActive == "Public Sale") {
        const NFT_PRICE = await contractObj.methods.PUBLICSALE_PRICE().call();
        const getNFTPrice = await web3.utils.fromWei(NFT_PRICE.toString());
        setNftPrice(getNFTPrice);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(
      GalacticEagleABI,
      GalacticEagleContract
    );
    if (account) {
      getBalanceOfFun();
    }

    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY));
    const totalSupply = await contractObj.methods.totalSupply().call();
    setTotalSupply(Number(totalSupply));
  };

  useEffect(() => {
    getCurrentMintingDetails();
    getContractDetails();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}

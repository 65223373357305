import React, { useState } from "react";
import {
  Box,
  Container,
  makeStyles,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineReddit } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import IconButton from "@material-ui/core/IconButton";
import DirectionsIcon from "@material-ui/icons/Directions";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { FaDiscord } from "react-icons/fa";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import SaveIcon from "@material-ui/icons/Save";
import Icon from "@material-ui/core/Icon";
import axios from "axios";
import { Loader } from "react-feather";
import apiConfig from "src/Apiconfig/ApiConfig";
import { ToastContainer, toast } from "react-toastify";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    backgroundColor: "#000",
    borderTop: "1px solid #525252",
    padding: "60px 60px",

    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(4),
    },
    "& p": {
      fontSize: "12px",
      margin: "0",
      padding: "2px 0",
    },
    "& a": {
      fontSize: "16px",
      margin: "0",
      cursor: "pointer",
      padding: "10px 0",
      textDecoration: "none",
      color: "#989797",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    justifyContent: "center",
    "& a": {
      width: "30px",
      height: "30px",
      borderRadius: " 50%",
      backgroundColor: " #fff",
      color: "#000",
      margin: "0 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      padding: "0",
      "&:hover": {
        backgroundColor: "#FFCD01",
        color: "#fff",
      },
    },
  },
}));
const isValidEmail = (value) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
  return re.test(String(value).toLowerCase());
};

export default function Liquidity() {
  const classes = useStyles();
  const [formValue, setFormValue] = useState({
    email: "",
  });
  const _onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const temp = { ...formValue, [name]: value };
    setFormValue(temp);
  };
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [popup, setPopup] = useState(false);
  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
  const contactUSAPI = async () => {
    setIsSubmit(true);
    if (formValue.email !== "" && isValidEmail(formValue.email)) {
      try {
        setLoading(true);
        setIsSubmit(false);
        const res = await axios.post(apiConfig.contactUS, {
          email: formValue.email,
        });

        if (res.data.statusCode === 200) {
          setPopup(true);

          toast.success(res.data.responseMessage);
        } else {
          toast.warn(res.data.responseMessage);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.response) {
          toast.warn(err.response.data.responseMessage);
        } else {
          toast.warn(err.error);
        }
      }
    }
  };

  return (
    <Box className={classes.footerSection}>
      <Container maxWidth='lg' align='center'>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box className={classes.bannerText}>
              <img src='images/logo.svg' style={{ width: "90px" }} alt='Logo' />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box className={classes.bannerText}>
              <Box>
                {/* <TextField
              style={{ marginTop: "18px" }}
              id="outlined-basic"
              placeholder="Enter your Email"
              variant="outlined"
              fullWidth endIcon={<Icon>send</Icon>}
            /> */}

                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant='outlined'
                />
                <OutlinedInput
                  id='outlined-adornment-weight'
                  placeholder='Enter your Email'
                  // value={values.weight}
                  fullWidth
                  inputProps={{ loading }}
                  error={
                    isSubmit &&
                    formValue.email == "" &&
                    !isValidEmail(formValue.email)
                  }
                  helperText={
                    isSubmit &&
                    formValue.email == "" &&
                    !isValidEmail(formValue.email) &&
                    "please enter valid email"
                  }
                  endAdornment={
                    <InputAdornment position='end'>
                      <button
                        class='MuiButtonBase-root MuiButton-root MuiButton-text'
                        tabindex='0'
                        type='button'
                        onClick={() => {
                          if (loading) {
                            return false;
                          } else {
                            contactUSAPI();
                          }
                        }}
                        style={{ background: "#5dcadb" }}
                        disabled={loading}
                      >
                        <span>
                          <ArrowForwardIcon />
                        </span>
                        {loading && <ButtonCircularProgress />}
                      </button>
                    </InputAdornment>
                  }
                  aria-describedby='outlined-weight-helper-text'
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  labelWidth={0}
                  onChange={_onInputChange}
                  name='email'
                />

                <p style={{ color: "red", textAlign: "left" }}>
                  {isSubmit &&
                    formValue.email == "" &&
                    !isValidEmail(formValue.email) &&
                    "please enter valid email"}
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box className={classes.bannerText}>
              <ul className={classes.menuul}>
                {/* <li>
            <div>
              <a
                href=" https://www.youtube.com/channel/UCLIhq1B9PjXD74-6f54UgLw"
                target="_blank"
                style={{ backgroundColor: "#fff", color: "red" }}
              >
                <BsYoutube />
              </a>
            </div>
          </li> */}
                {/* <li>
            <div>
              <a
                href=" https://www.reddit.com/r/Crypto_HoldemNFT/"
                target="_blank"
                style={{ backgroundColor: "green", color: "#fff" }}
              >
                <AiOutlineReddit />
              </a>
            </div>
          </li> */}
                <li>
                  <div>
                    <a
                      href='  https://discord.gg/5s2Fuf4CEN'
                      target='_blank'
                      style={{ backgroundColor: "#3f1178", color: "#fff" }}
                    >
                      <FaDiscord />
                    </a>
                  </div>
                </li>
                <li>
                  {" "}
                  <div>
                    <a
                      href='https://twitter.com/CryptoholdemNFT'
                      target='_blank'
                      style={{ backgroundColor: "#3cdfff", color: "#fff" }}
                    >
                      <AiOutlineTwitter />
                    </a>
                  </div>
                </li>
                <li>
                  <div>
                    <a
                      href='https://www.instagram.com/cryptoholdemnft/'
                      target='_blank'
                    >
                      <AiOutlineInstagram />
                    </a>
                  </div>
                </li>
              </ul>

              <Typography variant='body2'>@ Crypto Holdem LLC</Typography>
              <Link to='/terms'>
                {" "}
                <Typography variant='body2'>
                  Crypto Holdem LLC Terms & Service
                </Typography>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box className={classes.bannerText}>
              <a
                href='https://nftcalendar.io/event/crypto-holdem-nft/'
                target='_blank'
              >
                <img
                  src='images/footerimg_1.png'
                  style={{ width: "120px" }}
                  alt='Logo'
                />
              </a>
            </Box>
          </Grid>
        </Grid>
        {/* <img src="images/logo.png" style={{ width: "200px" }} alt="Logo" 
       />
        <ul className={classes.menuul}>
          <li>
            <div>
              <a
                href=" https://discord.gg/8pkAdzPcCA"
                target="_blank"
                style={{ backgroundColor: "#3f1178", color: "#fff" }}
              >
                <FaDiscord />
              </a>
            </div>
          </li>
          <li>
            {" "}
            <div>
              <a
                href="https://twitter.com/cryptoholdemnft"
                target="_blank"
                style={{ backgroundColor: "#3cdfff", color: "#fff" }}
              >
                <AiOutlineTwitter />
              </a>
            </div>
          </li>
          <li>
            <div>
              <a
                href="https://instagram.com/cryptoholdemnft?utm_medium=copy_link"
                target="_blank"
              >
                <AiOutlineInstagram />
              </a>
            </div>
          </li>
        </ul>
        <Link to="/terms">Terms of Service</Link>
        <p>© 2021 Crypto Holdem. All rights reserved</p> */}
      </Container>
    </Box>
  );
}
